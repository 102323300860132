import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBCarousel, MDBCarouselInner, MDBCarouselItem } from "mdbreact"

import Layout from "../components/TLCLayout"

import wash1 from "../images/services/wash/782811_orig.jpg"
import wash2 from "../images/services/wash/3996043_orig.jpg"
import wash3 from "../images/services/wash/4671555_orig.jpg"
import wash4 from "../images/services/wash/6676783_orig.jpg"
import wash5 from "../images/services/wash/6968660_orig.jpg"
import wash6 from "../images/services/wash/9286595_orig.jpg"

import correction1 from "../images/services/correction/3724413.jpg"

import coatings1 from "../images/services/coatings/audi.jpg"
import coatings2 from "../images/services/coatings/bc-08.jpg"
import coatings3 from "../images/services/coatings/bc-04.jpg"
import coatings4 from "../images/services/coatings/bc-06.jpg"

import film1 from "../images/services/film/film1.jpg"
import film2 from "../images/services/film/film2.jpg"
import film3 from "../images/services/film/film3.jpg"
import film4 from "../images/services/film/film4.jpg"
import film5 from "../images/services/film/film5.jpg"
import film6 from "../images/services/film/film6.jpg"


const ServicesPage = () => (
    <Layout heroTitle="Services" heroImageUrl="./images/hero-dark.jpg">
        <a name="wash" />
        {/* Signature Wash and Detail */}
        <MDBContainer style={{paddingTop: '100px'}}>
            <MDBRow>
                <MDBCol>
                    <h3>Signature Wash and Wax Detail</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="8">
                    <p>Sometimes all your vehicle needs to looks great is a deep cleaning and some added protection. For these vehicles we offer our Signature Wash and Wax Detail. This is a full exterior detail that not only gets everything fresh and clean, but also adds protection to keep it looking great for months. This service includes:</p>
                    <ul style={{paddingLeft: '50px'}}>
                        <li>Exterior hand wash, then dried with compress air and high quality microfiber towels to prevent inducing swirls</li>
                        <li>Excess water in door, hood, and trunk jams blotted dry</li>
                        <li>Clean the wheel faces, wheel barrels, tires, and fender wells</li>
                        <li>Dress tires and fender wells with low gloss, non-sling dressing</li>
                        <li>Use clay bar on paint, glass, and wheels to remove bonded contaminants</li>
                        <li>Door jams cleaned and sealed with Car Pro Reload</li>
                        <li>All glass cleaned inside and out</li>
                        <li>Exterior plastic trim cleaned and dressed, metal trim polished and sealed</li>
                        <li>High quality sealant applied to paint, glass, and wheels (6-8 months of protection depending on how vehicle is maintained)</li>
                    </ul>
                    <a name="correction" />
                    <p>Pricing starts at $200.</p>
                </MDBCol>
                <MDBCol md="4">
                    <MDBCarousel
                        activeItem={1}
                        length={6}
                        showControls={false}
                        showIndicators={false}
                        className="z-depth-1"
                        style={{padding: 0, marginTop: '25px'}}
                    >
                        <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <img className="d-block w-100" src={wash1} />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId="2">
                                <img className="d-block w-100" src={wash2} />
                            </MDBCarouselItem>          
                            <MDBCarouselItem itemId="3">
                                <img className="d-block w-100" src={wash3} />
                            </MDBCarouselItem> 
                            <MDBCarouselItem itemId="4">
                                <img className="d-block w-100" src={wash4} />
                            </MDBCarouselItem> 
                            <MDBCarouselItem itemId="5">
                                <img className="d-block w-100" src={wash5} />
                            </MDBCarouselItem> 
                            <MDBCarouselItem itemId="6">
                                <img className="d-block w-100" src={wash6} />
                            </MDBCarouselItem>                                                                                                                                   
                        </MDBCarouselInner>
                    </MDBCarousel>
                </MDBCol>
            </MDBRow>                                                                                                                                       
        </MDBContainer>
        

        {/*  Paint Correction */}
        <MDBContainer style={{paddingTop: '50px'}}>
            <MDBRow>
                <MDBCol>
                    <h3>Paint Correction</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="4">
                    <MDBCarousel
                        activeItem={1}
                        length={1}
                        showControls={false}
                        showIndicators={false}
                        className="z-depth-1"
                        style={{padding: 0, marginTop: '25px'}}
                    >
                        <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <img className="d-block w-100" src={correction1} />
                            </MDBCarouselItem>                                                                                                                                  
                        </MDBCarouselInner>
                    </MDBCarousel>
                </MDBCol>                
                <MDBCol md="8">
                    <p>Paint correction, also known as paint polishing, is the act of leveling paint to remove defects, ​including wash induced swirls, light scratches, hard water spots, bird dropping etchings, and scuffs via machine buffing. Through machine polishing we are also able to drastically improve the gloss of a vehicle’s paintwork. With a TLC Auto Detail paint correction, the defects will be actually removed, ​not just hidden like many other detail shops offer. Depending on the condition of your paint and the desired results, we can perform either a one step or multi-step paint correction. The services below range in price from $600 to over $2000 depending on how much labor is required to reach the desired goals.</p>

                    ​​<h5 style={{paddingTop: '25px'}}>Rejuvenation Detail (Single Step Paint Correction)</h5>
                    <p>This service is designed mainly for brand new or fairly new vehicles that only have minor defects that need to be corrected. Most people would think that a new or nearly new vehicle that was just delivered wouldn’t need to be corrected, but unfortunately this is far from the truth. Most new vehicles need at least a one step correction service, if not more. Defects can include wash induced marring from the dealership wash bay, holograms from the production line, or even over spray from outside repairs while sitting on the lot at the dealership.  With this package your vehicle will receive a one step machine polish before the surface is protected with sealant or wax (for maximum protection after correction check out our Modesta coating!!). For a new vehicle with light swirls and defects you can expect 90%+ correction, and you will truly have a brand new car. If you have a daily driven vehicle with some heavier defects, this package can be appropriate since it might not be a fiscally intelligent idea to spend lots of money correcting a car that sits out in the elements 24/7. For a vehicle that falls into this category you can expect 65%-80% correction depending on the initial condition of the paintwork.</p>
                    <p>​This service includes:</p>
                    <ul style={{paddingLeft: '50px'}}>
                        <li>Hand wash exterior of vehicle, dry with compressed air, blot dry door, trunk, and hood jams</li>
                        <li>Clean the wheel faces, wheel barrels, tires, and fender wells</li>
                        <li>Dress tires and fender wells with low gloss, non-sling, dressing</li>
                        <li>Use clay bar on paint, glass, and wheels to removed bonded contaminants</li>
                        <li>Single step of machine polishing performed on exterior paintwork</li>
                        <li>Car Pro Reload synthetic sealant applied to paintwork, glass, and wheels for protection</li>
                        <li>Door jams cleaned and sealed with Car Pro Reload synthetic sealant</li>
                        <li>Exterior plastic trim cleaned and dressed, exterior metal trim polished and sealed</li>
                        <li>Interior lightly vacuumed and wiped down</li>
                    </ul>
                    <p>​Pricing starts at $600.</p>
                    
                    <h5 style={{paddingTop: '25px'}}>Restoration Detail (2 Step Paint Correction)</h5>
                    <p>While a single step off correction will make a drastic difference in the gloss and clarity of a vehicle's paint, there are some instances where the desired results are not achievable with just one step off correction. For a vehicle that has heavier defects, or a client looking for as close to perfect as possible, we offer our Restoration Detail. This service includes everything from the Rejuvenation Detail and adds and additional step of paint correction. Depending on the initial condition of the paintwork, this service will remove 85-90% of the defects. Short of sanding a finish, this is the best correction that you can ask for. </p>
                    <a name="coatings" />
                    <p>Pricing starts at $1000.</p>
                    
                </MDBCol>
            </MDBRow>                                                                                                                                       
        </MDBContainer>  
        

       {/* Modesta Coatings */}
        <MDBContainer style={{paddingTop: '50px'}}>
            <MDBRow>
                <MDBCol>
                    <h3>Modesta Coatings</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="8">
                    <p>TLC Auto Detail is proud to be a Modesta Certified Installer. Modesta Glass Coatings are regarded as the best performing and longest lasting coatings currently available. Modesta Glass Coatings are silica-based and provide amazing chemical protection to paint, wheels, and paint protection film. This incredible protection means bird droppings, bug guts, and other environmental hazards will not have a chance to permanently etch your vehicle’s finish. On top of the second to none chemical protection, Modesta Glass Coatings are also the most hydrophobic coatings available. This creates a self cleaning effect that will not only keep your vehicle cleaner, but make your vehicle easier to clean when you do wash it.</p>
                    <p>On top of the unequalled protection offered, Modesta Coatings also add astonishing gloss and luster to your paintwork that other coatings can’t compare to. Check out our gallery
                    ​for more examples!</p>
                    <p>With Modesta applied to your paintwork and/or paint protection film, you will not need to apply traditional waxes or sealants for protection, so maintenance is incredibly easy with simple washes and maintenance sprays. We recommend yearly inspections of the coating to insure it is performing at its optimal level.</p>
                    
                    <h5 style={{paddingTop: '25px'}}>Modesta BC-08 Neosilica 3+ year coating</h5>
                    <p>Modesta BC-08 Neosilica Matrix Coating is a fantastic entry-level coating from Modesta that compete with the premium coating offerings from many other brands. BC-08 is a great way to protect your daily driven vehicle from environmental hazards while also easing maintenance. Perfect for a vehicle that is only going to be leased for a few years an older vehicle that may already be a little less than perfect. With Modesta BC-08 you can get into a high quality paint coating without the premium price tag.</p>
                    <p>​Starting at $895 (plus Decontamination or Paint Correction Detail)</p>
                    
                    <h5 style={{paddingTop: '25px'}}>Modesta BC-04 Nano Titanium Coating 7+ year coating</h5>
                    <p>Modesta BC-04 is the flagship product from Modesta.  This coating has an expected lifespan of over 7 years with proper maintenance. This is a premium coating that has yet to be match in performance or looks by other manufacturers. This coating give amazing chemical protection, a self cleaning effect, and amazing luster to keep your vehicle protected and looking amazing for many, many years. The inorganic nature of this coating also makes it immune to oxidation and makes it resistant to temperatures up to 900*C.</p>
                    <p>Starting at $1495 (A Paint Correction Detail is required to prep surface for application of this coating.)</p>

                    <h5 style={{paddingTop: '25px'}}>​Modesta BC-06 High Temperature Wheel Coating</h5>
                    <p>Modesta BC-06 is a sprayable coating that uses its tight molecular structure to give amazing high temperature protection to your wheels and painted brake calipers. The incredible chemical protection will help prevent hot, corrosive brake dust from etching the surface of your calipers and wheels. With this coating installed, your wheels and calipers will be incredibly easy to clean and will stay cleaner longer. This coating can be installed to just the faces of the wheels while they are installed on the vehicle, or we can remove the wheels to coat the calipers, inner wheel barrels, and wheel faces.</p>
                    <p>​Starting at $300 for Coating Wheel Faces</p>
                    <p>Starting at $600 for Removing Wheels and Coating Calipers, Inner Wheel Barrels, and Wheel Faces</p>
                    
                    <h5 style={{paddingTop: '25px'}}>Modesta Leather Protection System</h5>
                    <p>A brand new offering from Modesta is their Leather Protection System. This two-part system is designed to give a level of protection to your interior leather that hasn’t been available before now. The Modesta Leather Protection System offers protection from stains as well as abrasion protection on heavily trafficked areas like raised seat bolsters. With this system applied, your leather will be easier to maintain, less prone to staining, and less likely to wear.</p>
                    <a name="film" />
                    <p>Starting at $595 (includes interior detail and prep)</p>
                </MDBCol>
                <MDBCol md="4">
                    <MDBCarousel
                        activeItem={1}
                        length={4}
                        showControls={false}
                        showIndicators={false}
                        className="z-depth-1"
                        style={{padding: 0, marginTop: '25px'}}
                    >
                        <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <img className="d-block w-100" src={coatings1} />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId="2">
                                <img className="d-block w-100" src={coatings2} />
                            </MDBCarouselItem>          
                            <MDBCarouselItem itemId="3">
                                <img className="d-block w-100" src={coatings3} />
                            </MDBCarouselItem> 
                            <MDBCarouselItem itemId="4">
                                <img className="d-block w-100" src={coatings4} />
                            </MDBCarouselItem>                                                                                                                            
                        </MDBCarouselInner>
                    </MDBCarousel>
                </MDBCol>
            </MDBRow>                                                                                                                                       
        </MDBContainer> 
        

        {/*  Film */}
        <MDBContainer style={{paddingTop: '50px'}}>
            <MDBRow>
                <MDBCol>
                    <h3>Paint Protection Film</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="4">
                    <MDBCarousel
                        activeItem={1}
                        length={6}
                        showControls={false}
                        showIndicators={false}
                        className="z-depth-1"
                        style={{padding: 0, marginTop: '25px'}}
                    >
                        <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <img className="d-block w-100" src={film1} />
                            </MDBCarouselItem>  
                            <MDBCarouselItem itemId="2">
                                <img className="d-block w-100" src={film2} />
                            </MDBCarouselItem>  
                            <MDBCarouselItem itemId="3">
                                <img className="d-block w-100" src={film3} />
                            </MDBCarouselItem>  
                            <MDBCarouselItem itemId="4">
                                <img className="d-block w-100" src={film4} />
                            </MDBCarouselItem>    
                            <MDBCarouselItem itemId="5">
                                <img className="d-block w-100" src={film5} />
                            </MDBCarouselItem>  
                            <MDBCarouselItem itemId="6">
                                <img className="d-block w-100" src={film6} />
                            </MDBCarouselItem>                                                                                                                                                                                                                                                                            
                        </MDBCarouselInner>
                    </MDBCarousel>
                </MDBCol>                
                <MDBCol md="8">
                    <p>TLC Auto Detail is excited to announce that we are the only Clear Guard Nano Certified Installer in the Northern, VA and DC Metro Area!  Clear Guard Nano is latest in clear paint protection film technology.  It features a revolutionary nano top coat with self-healing benefits when exposed to the sun.  This means it has the unique ability to repair surface scratches with infrared, ultraviolet rays, or heat.  This allows the film to self heal micro-scratches and swirls even in cold weather without the need of external heat sources.</p>
                    <p>The nanotechnology top coat offers a super hydrophobic surface that has a self cleaning effect and helps protect the film from staining and etching from environmental hazards.  It also results in the highest reflectivity (gloss) and transparency of any film on the market.  The result is a film that has less texture and more gloss than the next leading film on the market.</p>

                    <p>ClearGuard Nano™ SR Features:</p>
                    <ol style={{paddingLeft: '25px'}}>
                        <li>Solar Reconvergence - self-healing minor scratches or swirls with exposure to the sun.</li>
                        <li>Anti-Etch and Anti-Stain durable coating.</li>
                        <li>Highest Liquid Gloss Factor of any film on the market with unsurpassed depth and brilliance that creates a mirror finish on your vehicle.</li>
                        <li>Clearest film on the market.</li>
                        <li>Durable PET base urethane to protect underlying paint from scratches and rock chips.</li>
                        <li>Tested and Approved by the best installation facilities in the world.</li>
                    </ol>

                    <p>Clear Guard Nano is, in our opinion, the best paint protection film on the market when it comes to looks, protection, and longevity.  Every wrap we do is 100% custom cut to give the cleanest install possible.  This includes not only wrapping every edge when possible, but also removing trim and emblems to give a seamless install every time.  We can wrap as must or as little of your vehicle as you would like.  We often wrap entire vehicles to give the most protection, but we do have some other popular packages to give protection to the areas that can benefit from it most.</p>
                </MDBCol>
            </MDBRow>                                                                                                                                       
        </MDBContainer>  


    </Layout>
)

export default ServicesPage